import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {CImage, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler} from '@coreui/react'

import {AppSidebarNav} from './AppSidebarNav'

import logoF from 'src/assets/brand/logoNgangWhite.png'
import logo from 'src/assets/brand/faviconWhite.png'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'
import {motion} from "framer-motion";
import {Link} from "react-router-dom";

const AppSidebar = () => {
	const dispatch = useDispatch()
	const unfoldable = useSelector((state) => state.sidebarUnfoldable)
	const sidebarShow = useSelector((state) => state.sidebarShow)

	return (
		<CSidebar
			position="fixed"
			unfoldable={unfoldable}
			visible={sidebarShow}
			onVisibleChange={(visible) => {
				dispatch({ type: 'set', sidebarShow: visible })
			}}
		>
			<CSidebarBrand className="d-none d-md-flex" to="/">
				<motion.div whileHover={{scale: 1.05}}>
					<Link to={"/"}>
						<CImage fluid className="sidebar-brand-full" src={logoF} width={170} alt="logo"/>
						<CImage className="sidebar-brand-narrow" src={logo} height={35} alt="logo"/>
					</Link>
				</motion.div>
			</CSidebarBrand>
			<CSidebarNav>
				<SimpleBar>
					<AppSidebarNav items={navigation} />
				</SimpleBar>
			</CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({type: 'set', sidebarUnfoldable: !unfoldable})}
      />
		</CSidebar>
	)
}

export default React.memo(AppSidebar)
