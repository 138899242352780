import React from 'react'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Bank = React.lazy(() => import('./views/bank/Bank'));
const PaymentDeposit = React.lazy(() => import('./views/payment/PaymentDeposit'));
const EarnMoney = React.lazy(() => import('./views/payment/EarnMoney'));
const UserProfile = React.lazy(() => import('./views/user/UserProfile'));
const TransactionHistory = React.lazy(() => import('./views/transaction/TransactionHistory'));
const AppDownload = React.lazy(() => import('./views/download/AppDownload'));
const CheckProfile = React.lazy(() => import("./views/tool/CheckProfile"));
const ChangePassword = React.lazy(() => import('./views/user/ChangePassword'));
const PageNotFound = React.lazy(() => import('./views/404/404'));
const BillTransfer = React.lazy(() => import('./views/bill/BillTransfer/ListBanks'));
const TransferVietcombank = React.lazy(() => import('./views/bill/BillTransfer/Banks/Vietcombank'));
const TransferTPBank = React.lazy(() => import('./views/bill/BillTransfer/Banks/TPBank'));
const TransferBIDV = React.lazy(() => import('./views/bill/BillTransfer/Banks/BIDV'));
const TransferTechcombank = React.lazy(() => import('./views/bill/BillTransfer/Banks/Techcombank'));
const TransferMBBank = React.lazy(() => import('./views/bill/BillTransfer/Banks/MBBank'));
const TransferMSBank = React.lazy(() => import('./views/bill/BillTransfer/Banks/MSBank'));
const TransferVPBank = React.lazy(() => import('./views/bill/BillTransfer/Banks/VPBank'));
const TransferACBBank = React.lazy(() => import('./views/bill/BillTransfer/Banks/ACBBank'));
const TransferVTBank = React.lazy(() => import('./views/bill/BillTransfer/Banks/VTBank'));
const TransferMomo = React.lazy(() => import('./views/bill/BillTransfer/Banks/Momo'));
const TransferZalo = React.lazy(() => import('./views/bill/BillTransfer/Banks/Zalo'));

const BillFluctuation = React.lazy(() => import('./views/bill/BillFluctuation/ListBanks'));
const FluctuationVietcombank = React.lazy(() => import('./views/bill/BillFluctuation/Banks/Vietcombank'));
const FluctuationTPBank = React.lazy(() => import('./views/bill/BillFluctuation/Banks/TPBank'));
const FluctuationBIDV = React.lazy(() => import('./views/bill/BillFluctuation/Banks/BIDV'));
const FluctuationTechcombank = React.lazy(() => import('./views/bill/BillFluctuation/Banks/Techcombank'));
const FluctuationMBBank = React.lazy(() => import('./views/bill/BillFluctuation/Banks/MBBank'));
const FluctuationMSBank = React.lazy(() => import('./views/bill/BillFluctuation/Banks/MSBank'));
const FluctuationVPBank = React.lazy(() => import('./views/bill/BillFluctuation/Banks/VPBank'));
const FluctuationACBBank = React.lazy(() => import('./views/bill/BillFluctuation/Banks/ACBBank'));
const FluctuationVTBank = React.lazy(() => import('./views/bill/BillFluctuation/Banks/VTBank'));
const FluctuationMomo = React.lazy(() => import('./views/bill/BillFluctuation/Banks/Momo'));
const FluctuationZalo = React.lazy(() => import('./views/bill/BillFluctuation/Banks/Zalo'));

const BillSurplus = React.lazy(() => import('./views/bill/BillSurplus/ListBanks'));
const SurplusVietcombank = React.lazy(() => import('./views/bill/BillSurplus/Banks/Vietcombank'));
const SurplusTPBank = React.lazy(() => import('./views/bill/BillSurplus/Banks/TPBank'));
const SurplusBIDV = React.lazy(() => import('./views/bill/BillSurplus/Banks/BIDV'));
const SurplusTechcombank = React.lazy(() => import('./views/bill/BillSurplus/Banks/Techcombank'));
const SurplusMBBank = React.lazy(() => import('./views/bill/BillSurplus/Banks/MBBank'));
const SurplusMSBank = React.lazy(() => import('./views/bill/BillSurplus/Banks/MSBank'));
const SurplusVPBank = React.lazy(() => import('./views/bill/BillSurplus/Banks/VPBank'));
const SurplusACBBank = React.lazy(() => import('./views/bill/BillSurplus/Banks/ACBBank'));
const SurplusVTBank = React.lazy(() => import('./views/bill/BillSurplus/Banks/VTBank'));
const SurplusMomo = React.lazy(() => import('./views/bill/BillSurplus/Banks/Momo'));
const SurplusZalo = React.lazy(() => import('./views/bill/BillSurplus/Banks/Zalo'));

const Package = React.lazy(() => import('./views/package/Package'));

const routes = [
    {path: '/', exact: true, name: ''},
    {path: '/dashboard', name: 'Bảng Điều Khiển', element: Dashboard},
    {path: '/bank', name: 'Fake App Ngân Hàng', element: Bank},
    {path: '/payment/deposit', name: 'Nạp Tiền', element: PaymentDeposit},
    {path: '/payment/earn-money', name: 'Kiếm Tiền', element: EarnMoney},
    {path: '/user/profile', name: 'Trang Cá Nhân', element: UserProfile},
    {path: '/user/change-password', name: 'Đổi Mật Khẩu', element: ChangePassword},
    {path: '/transaction/history', name: 'Lịch Sử Giao Dịch', element: TransactionHistory},
    {path: '/tool/check-profile', name: 'Kiểm Tra CCCD CMND SDT', element: CheckProfile},
    {path: '/download', name: 'Tải App Fake Ngân hàng', element: AppDownload},

    {path: '/bill/bill-transfer', name: 'Fake Bill Chuyển Khoản', element: BillTransfer},
    {path: '/bill/bill-transfer/vietcombank', name: 'Fake Bill Chuyển Khoản Vietcombank', element: TransferVietcombank},
    {path: '/bill/bill-transfer/tpbank', name: 'Fake Bill Chuyển Khoản TPBank', element: TransferTPBank},
    {path: '/bill/bill-transfer/bidv', name: 'Fake Bill Chuyển Khoản BIDV', element: TransferBIDV},
    {path: '/bill/bill-transfer/techcombank', name: 'Fake Bill Chuyển Khoản Techcombank', element: TransferTechcombank},
    {path: '/bill/bill-transfer/mbbank', name: 'Fake Bill Chuyển Khoản MBBank', element: TransferMBBank},
    {path: '/bill/bill-transfer/msbank', name: 'Fake Bill Chuyển Khoản MSBank', element: TransferMSBank},
    {path: '/bill/bill-transfer/vpbank', name: 'Fake Bill Chuyển Khoản VPBank', element: TransferVPBank},
    {path: '/bill/bill-transfer/acbbank', name: 'Fake Bill Chuyển Khoản ACBBank', element: TransferACBBank},
    {path: '/bill/bill-transfer/vtbank', name: 'Fake Bill Chuyển Khoản VTBank', element: TransferVTBank},
    {path: '/bill/bill-transfer/momo', name: 'Fake Bill Chuyển Khoản Momo', element: TransferMomo},
    {path: '/bill/bill-transfer/zalo', name: 'Fake Bill Chuyển Khoản Zalo', element: TransferZalo},

    {path: '/bill/bill-flutuation', name: 'Fake Bill Biến Động', element: BillFluctuation},
    {path: '/bill/bill-flutuation/vietcombank', name: 'Fake Bill Biến Động Vietcombank', element: FluctuationVietcombank},
    {path: '/bill/bill-flutuation/tpbank', name: 'Fake Bill Biến Động TPBank', element: FluctuationTPBank},
    {path: '/bill/bill-flutuation/bidv', name: 'Fake Bill Biến Động BIDV', element: FluctuationBIDV},
    {path: '/bill/bill-flutuation/techcombank', name: 'Fake Bill Biến Động Techcombank', element: FluctuationTechcombank},
    {path: '/bill/bill-flutuation/mbbank', name: 'Fake Bill Biến Động MBBank', element: FluctuationMBBank},
    {path: '/bill/bill-flutuation/msbank', name: 'Fake Bill Biến Động MSBank', element: FluctuationMSBank},
    {path: '/bill/bill-flutuation/vpbank', name: 'Fake Bill Biến Động VPBank', element: FluctuationVPBank},
    {path: '/bill/bill-flutuation/acbbank', name: 'Fake Bill Biến Động ACBBank', element: FluctuationACBBank},
    {path: '/bill/bill-flutuation/vtbank', name: 'Fake Bill Biến Động VTBank', element: FluctuationVTBank},
    {path: '/bill/bill-flutuation/momo', name: 'Fake Bill Biến Động Momo', element: FluctuationMomo},
    {path: '/bill/bill-flutuation/zalo', name: 'Fake Bill Biến Động Zalo', element: FluctuationZalo},

    {path: '/bill/bill-surplus', name: 'Fake Bill Số Dư', element: BillSurplus},
    {path: '/bill/bill-surplus/vietcombank', name: 'Fake Bill Số Dư Vietcombank', element: SurplusVietcombank},
    {path: '/bill/bill-surplus/tpbank', name: 'Fake Bill Số Dư TPBank', element: SurplusTPBank},
    {path: '/bill/bill-surplus/bidv', name: 'Fake Bill Số Dư BIDV', element: SurplusBIDV},
    {path: '/bill/bill-surplus/techcombank', name: 'Fake Bill Số Dư Techcombank', element: SurplusTechcombank},
    {path: '/bill/bill-surplus/mbbank', name: 'Fake Bill Số Dư MBBank', element: SurplusMBBank},
    {path: '/bill/bill-surplus/msbank', name: 'Fake Bill Số Dư MSBank', element: SurplusMSBank},
    {path: '/bill/bill-surplus/vpbank', name: 'Fake Bill Số Dư VPBank', element: SurplusVPBank},
    {path: '/bill/bill-surplus/acbbank', name: 'Fake Bill Số Dư ACBBank', element: SurplusACBBank},
    {path: '/bill/bill-surplus/vtbank', name: 'Fake Bill Số Dư VTBank', element: SurplusVTBank},
    {path: '/bill/bill-surplus/momo', name: 'Fake Bill Số Dư Momo', element: SurplusMomo},
    {path: '/bill/bill-surplus/zalo', name: 'Fake Bill Số Dư Zalo', element: SurplusZalo},

    {path: '/package/:type/:name', name: 'Danh Sách Gói', element: Package},

    {path: '*', name: "Trang Không Tồn Tại", element: PageNotFound},
]

export default routes
